<template>
  <template v-if="(typeof store.storeId !== 'undefined')">
    <template v-if="typeof page.items !== 'undefined' && page.items.length > 0">
      <template v-for="item in page.items" :key="item">
        <template v-if="item.name === 'image'">
          <wg-image :box="item" />
        </template>
        <template v-if="item.name === 'swipe'">
          <wg-swipe :box="item" />
        </template>
        <template v-if="item.name === 'video'">
          <wg-video :box="item" />
        </template>
        <template v-if="item.name === 'product'">
          <wg-product :box="item" />
        </template>
      </template>
    </template>
    <template v-else>
      123
    </template>
  </template>
  <rights />
  <tabbar />
</template>

<script>
import { toRefs, reactive, onMounted, inject, nextTick, watch } from 'vue'
import Tabbar from '../components/Tabbar'
import { WgImage, WgSwipe, WgVideo, WgProduct } from './diy/widget'
// import { gdMap } from '@/util/util'
import { Toast } from 'vant'
import Rights from '@/components/Rights.vue'

export default {
  components: {
    Rights,
    Tabbar,
    WgImage,
    WgSwipe,
    WgVideo,
    WgProduct
  },
  setup (props, context) {
    const post = inject('post')
    // const useRouter = inject('useRouter')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: {
        items: []
      },
      store: {},
      shop: true,
      value: '',
      active: 0,
      sonRefresh: true,
      cityCode: '',
      city: '',
      storeId: Cookies.get('storeId')
    })
    const refreshCom = () => {
      state.sonRefresh = false
      nextTick(() => {
        state.sonRefresh = true
      })
    }
    const onChange = (index) => {
      return new Promise((resolve) => {
        refreshCom()
        resolve(true)
      })
    }
    const onChangeCity = () => {
      var cityPicker = new window.AMapUI.MobiCityPicker({})
      cityPicker.on('citySelected', (cityInfo) => {
        cityPicker.hide()
        state.cityCode = cityInfo.areaCode
        state.city = cityInfo.name
        onChange(0)
      })
      cityPicker.show()
    }
    const init = () => {
      // Toast.loading({ forbidClick: true })
      // gdMap().then(AMap => {
      //   if (typeof Cookies.get('cityCode') !== 'undefined') {
      //     state.cityCode = Cookies.get('cityCode')
      //     state.city = Cookies.get('city')
      //   } else {
      //     var geolocation = new AMap.Geolocation({
      //       enableHighAccuracy: true,
      //       timeout: 10000
      //     })
      //     geolocation.getCurrentPosition((status, result) => {
      //       if (status === 'complete') {
      //         var geocoder = new AMap.Geocoder({})
      //         var lnglat = result.position // [116.396574, 39.992706]
      //         geocoder.getAddress(lnglat, (status, result) => {
      //           if (status === 'complete' && result.info === 'OK') {
      //             // alert(result.regeocode.addressComponent.citycode)
      //             // alert(JSON.stringify(result))
      //             state.cityCode = result.regeocode.addressComponent.citycode
      //             state.city = result.regeocode.addressComponent.city
      //             Cookies.set('cityCode', state.cityCode, { expires: 1 })
      //             Cookies.set('city', state.city, { expires: 1 })
      //           }
      //         })
      //       } else {
      //         Toast('定位失败,定位系统失效')
      //       }
      //     })
      //   }
      // })
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        state.store = res.data
        document.title = state.store.name
        state.page = res.data.page !== '' ? JSON.parse(res.data.page) : JSON.parse('{}')
      })
    }
    watch(() => state.citycode, (to, previous) => {
      Toast('重新加载')
    }, { deep: true })
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onChange,
      onChangeCity
    }
  }
}
</script>
