<template>
  <template v-if="box.param.column === '1'">
    <template v-for="item in products" :key="item">
      <div style="padding:10px;background:#fff;">
        <router-link :to="'/product/detail?productId=' + item.productId">
          <van-row gutter="10">
            <van-col span="8">
              <van-image
                width="100%"
                :src="item.image"
              />
            </van-col>
            <van-col span="16">
              <div style="position:relative;">
                <h4 class="van-multi-ellipsis--l2">{{ item.name }}</h4>
                <div style="margin:30px 0 0 0;">
                  <h4 style="color:#ff5000;display:inline;">&yen;{{ item.price }}</h4>
                  <h5 style="display:inline;float:right;color:#999;">已售 {{ item.sales + item.fictitiousSales }} 件</h5>
                </div>
              </div>
            </van-col>
          </van-row>
        </router-link>
      </div>
    </template>
  </template>
  <template v-else>
    <van-grid :border="false" :column-num="2" :gutter="5" :center="false">
      <van-grid-item v-for="item in products" :key="item.productId" :to="'/product/detail?productId=' + item.productId">
        <van-image :src="item.image" />
        <div style="background:#fff;">
          <h5 class="van-multi-ellipsis--l2" style="margin:7px 0;">{{ item.name }}</h5>
          <div style="color:#ff5000;">
            <h6 style="display:inline;">￥</h6>
            <h4 style="display:inline;">{{ item.price }}</h4>
            <h6 style="float:right;color:#999;">已售 {{ item.sales + item.fictitiousSales }} 件</h6>
          </div>
        </div>
      </van-grid-item>
    </van-grid>
  </template>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  components: {
  },
  props: {
    box: Object
  },
  setup (props, { emit }) {
    const post = inject('post')
    const state = reactive({
      products: []
    })
    const init = () => {
      post('/product.list', {
        productIds: props.box.param.ids
      }).then(res => {
        state.products = res.data.content
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
