<template>
  <van-row>
    <template v-for="item in box.items" :key="item">
      <van-col :span="24 / box.items.length">
        <div v-if="item.alias != '外部链接'">
          <router-link :to="item.link">
            <van-image width="100%" :src="item.url" />
          </router-link>
        </div>
        <div v-if="item.alias == '外部链接'" @click="toLink(item)">
          <van-image width="100%" :src="item.url" />
        </div>
      </van-col>
    </template>
  </van-row>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'

export default {
  components: {
  },
  props: {
    box: Object
  },
  setup (props, { emit }) {
    const state = reactive({
    })
    const init = () => {
    }
    const toLink = (item) => {
      console.log(item)
      window.location.href = item.link
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      toLink
    }
  }
}
</script>

<style scoped>
.van-image {
  display: inline;
}
</style>
