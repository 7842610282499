<template>
  <div style="height:20px;"></div>
  <van-tabbar v-model="tag" z-index="150" :placeholder="true">
    <van-tabbar-item name="home" icon="home-o" :to="{ name: 'Home'}">首页</van-tabbar-item>
    <van-tabbar-item name="product" icon="flower-o" :to="{ name: 'ProductList'}">商品</van-tabbar-item>
    <van-tabbar-item name="chat" icon="chat-o" to="/chat">消息</van-tabbar-item>
    <van-tabbar-item name="cart" icon="cart-o" to="/cart">购物车</van-tabbar-item>
    <van-tabbar-item name="user" icon="user-o" :to="{ name: 'User'}">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import Cookies from 'js-cookie'

export default {
  props: {
    active: {
      type: String,
      default: 'home'
    }
  },
  setup (props) {
    const state = reactive({
      tag: props.active,
      sellerId: Cookies.get('sellerId'),
      show: false
    })
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
