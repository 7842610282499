<template>
  <van-swipe :autoplay="3000">
    <van-swipe-item v-for="item in box.items" :key="item">
      <router-link :to="item.link">
        <van-image width="100%" :src="item.url" />
      </router-link>
    </van-swipe-item>
  </van-swipe>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'

export default {
  components: {
  },
  props: {
    box: Object
  },
  setup (props, { emit }) {
    const state = reactive({
    })
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
.van-image {
  display: inline;
}
</style>
